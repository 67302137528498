<template>
  <div>
    <default-app-bar > </default-app-bar>
     <template v-if="pageConfg">
      
      <!--/default-app-bar-->
      <div v-if="pageConfg">
        <component :is="getComponentForm()" :pageConfg="pageConfg" :key="keyPage"></component>
      </div>
      </template>
      <div v-else class="d-flex justify-center align-center" style="height: 100vh">
        <v-progress-circular
          :size="70"
          :width="7"
          color="purple"
          indeterminate
        ></v-progress-circular>
      </div>
  </div>

</template>

<script>

  import { NgForm, handler } from '@/components/gui/NgForm'
  import createComponentPageForm from '@/modules/DynamicPageForm'

  export default {
    name: 'default-form',
    data () {
      return {
        keyPage: 1,
        loading: true,
        pageConfg: null,
        showFilters: false,
      }
    },
    mounted(){
        console.log('fullpath', this.$route.params)
        const page = this.$store.getters.getPageConfig(this.$route.fullPath);
        if(page){
            this.pageConfg = page;
            /*this.querySelections().then(data => {
                     // this.items = data.items
                     // this.total = data.total
            });*/
        }else{
          this.getGuiPage(this.$route.fullPath).then((config) => {
                const p = JSON.parse(JSON.stringify(config));
                //const initForm = {};
                let act = 'edit';
                if (this.$route.params.extra) {
                  act = 'edit-' + this.$route.params.extra;
                }
                //console.log(p)
                this.pageConfg = p[act];
                //console.log(this.pageConfg)
                for(const a in p){
                    if(p[a] ){
                        this.$store.commit('addPage', p[a] );
                    }
                }
                this.keyPage++;
            
            });
        }
    },
    props: ['info'],
    methods: {
      getGuiPage (url) {
        return new Promise((resolve, reject) => {
            this.$http.post('api/v1/gui'+ url, { url }  ).then(data => {
                 resolve( data.config  );
            }).catch(err => {
                
            }).finally(() => (  this.loading = false ));
        });
      },
      getComponentForm() {
        return createComponentPageForm({}, this.pageConfg.tmpl);
      },
      getActionIcon(name){
            //console.log(this.pageConfg['actions'], '....', name)
            if(this.pageConfg && this.pageConfg['actions_btn'] && this.pageConfg['actions_btn'][name]){
                return this.pageConfg['actions_btn'][name]['icon'];
            }
            return "";
      },
     getActionName(name){
            //console.log(this.pageConfg['actions'], '....', name)
            if(this.pageConfg && this.pageConfg['actions_btn'] && this.pageConfg['actions_btn'][name]){
                return this.pageConfg['actions_btn'][name]['label'];
            }
            return "";
      },
      executeModuleAction(name){
          //console.log(name)
        if(this.pageConfg && this.pageConfg['actions_btn'] && this.pageConfg['actions_btn'][name]){
            //const act = this.pageConfg['actions'][name];
            //act.action = name;
          this.executeActionList({ action: this.pageConfg['actions_btn'][name], itemAction: null })
        }
          
      },
      executeActionList($event){
        //console.log($event.action)
        // const action = this.pageConfg['actions_btn'][$event.action.name];
        let hook = 'hook_'+$event.action.name;
        if($event.action.hook){
            hook = $event.action.hook;
        }
        window['managerHooks'].executeModuleAction(hook, $event.action, $event.itemAction, this);
        
      },
    }
  }
</script>
