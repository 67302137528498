  import { VCard, VCardText, VCardActions, VCardTitle, VContainer, VRow, VCol, VBtn, VIcon, VDatePicker, VCombobox, VSelect, VDivider } from 'vuetify/lib'
  import { NgForm, handler } from '@/components/gui/NgForm'
  
  export default function createComponentPageForm(configPage, tmpl){
      //  console.log(tmpl)
      return {
        // name: 'default-list',
        components: { VCard,  VCardText, VCardActions, VCardTitle, VContainer, VRow, VCol, VBtn, VDatePicker, VIcon, VCombobox, VSelect, VDivider },
        data () {
          return {
            form: null,
            keyForm: 1,
            loading: false,
            subtitle: ''
          }
        },
        props: ['pageConfg', 'itemId'],
        mounted () {
         //console.log(this.pageConfg)
         const form = new NgForm(this.pageConfg.form, this);
         this.form = new Proxy(form, handler);
         this.loadItem();
        },
        methods: {
          showLoading () {
            this.$store.commit('setLoading', true);
          },
          showMessage (msg) {
            this.$store.commit('setMessage', msg);
          },
          hideLoading () {
            this.$store.commit('setLoading', false);
          },
          showError (err) {
            this.$store.commit('setError', err);  
          },
          hideError () {
            this.$store.dispatch('clearError');  
          },
          loadItem() {
            //console.log(this.$route.params.id, this.itemId)
            if(this.$route.params.id || this.itemId){
              const id = this.itemId || this.$route.params.id;
              this.getItem(id).then(data => {
                        this.item = data.item;
                        this.subtitle = data.title;
                        this.loadForm(this.item);
                        
              });
            }
          },
          loadForm (item) {
            this.form.setValue({
                        ...item
                      });
          },
          getItem (id) {
            this.loading = true;
            //console.log(this.pageConfg.url_get+'/'+id)
            return new Promise((resolve, reject) => {
                //let params = this.pageConfg.params || {};
            
                this.$http.post( this.pageConfg.url_get+'/'+id)
                .then(data => {
                     let { item, title } = data
                     this.loading = false;
                     resolve({ item, title });
                }).catch(err => {
                  console.log(err);
                  this.loading = false;
                }).finally(() => (  this.loading = false ));
            });
          },
          clean() {
              this.form.clean();
          },
          getActionIcon(name){
            //console.log(this.pageConfg['actions'], '....', name)
            if(this.pageConfg && this.pageConfg['actions_btn'] && this.pageConfg['actions_btn'][name]){
                return this.pageConfg['actions_btn'][name]['icon'];
            }
            return "";
          },
          getActionName(name){
                //console.log(this.pageConfg['actions'], '....', name)
                if(this.pageConfg && this.pageConfg['actions_btn'] && this.pageConfg['actions_btn'][name]){
                    return this.pageConfg['actions_btn'][name]['label'];
                }
                return "";
          },
          getColorAction(name){
            if(this.pageConfg && this.pageConfg['actions_btn'] && this.pageConfg['actions_btn'][name] && this.pageConfg['actions_btn'][name]['color']){
                return this.pageConfg['actions_btn'][name]['color'];
            }
            return "primary";
          },
          getLabelAction(name){
            if(this.pageConfg && this.pageConfg['actions_btn'] && this.pageConfg['actions_btn'][name] && this.pageConfg['actions_btn'][name]['label']){
                return this.pageConfg['actions_btn'][name]['label'];
            }
            return "primary";
          },
           executeModuleAction(name){
              //console.log(name)
            if(this.pageConfg && this.pageConfg['actions_btn'] && this.pageConfg['actions_btn'][name]){
                //const act = this.pageConfg['actions'][name];
                //act.action = name;
              this.executeActionList({ action: this.pageConfg['actions_btn'][name], itemAction: null })
            }
              
          },
          executeActionList($event){
            //console.log($event.action)
            // const action = this.pageConfg['actions_btn'][$event.action.name];
            let hook = 'hook_'+$event.action.name;
            if($event.action.hook){
                hook = $event.action.hook;
            }
            window['managerHooks'].executeModuleAction(hook, $event.action, $event.itemAction, this);
            
          },
          executeItemAction(name){
            console.log(name, this.form.value, this.pageConfg)
            let action = null;
            if(this.pageConfg && this.pageConfg['actions_btn'] && this.pageConfg['actions_btn'][name]){
              action = this.pageConfg['actions_btn'][name];
            }
            let hook = 'hook_' + action.name;
            if(action.hook){
                hook = action.hook;
            }
            window['managerHooks'].executeModuleAction(hook, action, this.form.value, this);
          },
          isDisabledBtn(name){
            //console.log(act)
            let action = null;
            if(this.pageConfg && this.pageConfg['actions_btn'] && this.pageConfg['actions_btn'][name]){
              action = this.pageConfg['actions_btn'][name];
            }
            /*let hook = 'hook_' + action.name;
            if(action.hook){
                hook = action.hook;
            }*/
            //console.log(action)

            if(action.validate){
              //return true
              return window['managerHooks'].executeHook(action.validate, this.form, this);
            }
            return false;
          }
        },
        template: tmpl

      }
  }